import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Switch, Typography, Button} from '@material-ui/core';
import FlexGrow from 'components/Layout/FlexGrow';
import { updateOrganisation } from 'actions/organisationActions';
import { useDispatch } from 'react-redux';
import SuccessSnackbar from '../SuccessSnackbar';
import { PricingProposal, ApiIntegration, CustomerEmails, BrandingEmails, TrackingEmails } from './components';
// import {ApiIntegration} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',

    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    backgroundColor: '#FFFFFF',
  },
  manageYourSub: {
    display: 'flex',
    height: 64,
    alignItems: 'center',
    borderBottom: '1px solid #eee',
    paddingLeft: 24
  },
  container: {
    padding: 24
  },
  subscriptionItem: {
    borderRadius: 4,
    border: '1px solid #eee',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 0 0 0 rgba(63,63,68,0.15)',
    marginBottom: 24
  },
  subscriptionItemHeader: {
    display: 'flex',
    minHeight: '72px',
    alignItems: 'center',
    padding: '12px 24px',
    borderBottom: '1px solid #eee',
    backgroundColor: 'aliceblue',
  },
  subscriptionTitle: {
    fontSize: '18px'
  },
  subscriptionItemFooter: {
    padding: '24px',

  },
  footer: {
    float: 'right'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette['header'].main,
    '&:hover': {
      backgroundColor: theme.palette['header'].dark
    }
  },
}));


const Subscription = props => {
  const { currentOrganisation, error } = props;
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [values, setValues] = useState({
    active_integration: currentOrganisation.active_integration || false,
    emails_enable: currentOrganisation.emails_enable || false,
    custom_emails_enable: currentOrganisation.custom_emails_enable || false,
    tracking_emails_enable: currentOrganisation.tracking_emails_enable || false,
    low_stock_emails_enable: currentOrganisation.low_stock_emails_enable || false,
    final_sentance: currentOrganisation.final_sentance || '',
    slogan: currentOrganisation.slogan || '',
    company_name: currentOrganisation.company_name || false,
    correspondence_company_name: currentOrganisation.correspondence_company_name || false,
    tier: currentOrganisation.pricing_tier || false,
    logo: currentOrganisation.logo || "",
    preview: currentOrganisation.logo.url || "",
  })
  const [integrations, setIntegrations] = useState(currentOrganisation.integrations)
  const [proposal] = useState(currentOrganisation.pricing_proposal)
  const dispatch = useDispatch();

  const handleChange = event => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });

    if (event.target.name === 'active_integration' && event.target.checked === false) {
      let updatedIntegrations = integrations.map(integration => ({ ...integration, active: false }));
      setIntegrations(updatedIntegrations)
    }
  };


  const handleIntegrationChange = (event, id) => {
    event.persist();

    let updatedIntegrations = integrations.map(integration =>
      integration.id === id ? {
        ...integration, [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      } : integration
    )

    setIntegrations(updatedIntegrations);

    if (event.target.checked === true) {
      setValues({
        ...values,
        active_integration: true
      })
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    var formData = new FormData();

    Object.keys(values).forEach(e => {
      if (e === 'logo' || e === 'preview') return;
      formData.append(e, values[e]);
    });

    formData.append('integrations', JSON.stringify(integrations));


    if (values.logo[0]) {
      formData.append('logo', values.logo[0], values.logo[0].name)
    }
    // setSubmitted(false);
    dispatch(updateOrganisation(currentOrganisation.id, formData));

    if (error === null) {
      setOpenSnackbar(true);
    }
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  if (integrations === undefined) {
    return null
  }

  return (

    <div className={classes.root}>
      <div className={classes.manageYourSub}>
        <Typography style={{
          fontSize: 18
        }}>Manage Pricing</Typography>
      </div>

      <div className={classes.container}>


        <div className={classes.subscriptionItem}>

          <div className={classes.subscriptionItemHeader}>
            <Typography className={classes.subscriptionTitle} >Pricing Proposal</Typography >
            <FlexGrow />

            <Typography style={{ fontSize: '10px', paddingRight: '10px' }}>{proposal ? proposal.updated_at : ''}</Typography>

            <Typography style={{ fontSize: '18px', paddingRight: '10px' }}>Tier {values.tier}</Typography>
            <Button
              variant='outlined'
              href={proposal.proposal_url ? proposal.proposal_url.url : ''}
              target="_blank"
              // disabled={proposal.url === undefined}
            >
              View
            </Button>
          </div>

          <div className={classes.subscriptionItemFooter}>
            <PricingProposal values={values} />
          </div>

        </div>

        <div className={classes.subscriptionItem}>

          <div className={classes.subscriptionItemHeader}>
            <Typography className={classes.subscriptionTitle} >API Integration</Typography >
            <FlexGrow />
            <Typography style={{ fontSize: '10px', paddingRight: '10px' }}>Requires API Intergration</Typography>
            <Typography><b><span style={{ fontSize: '18px' }}>$10/</span></b>week</Typography>
            <Switch
              type='checkbox'
              checked={values.active_integration}
              value={values.active_integration}
              name="active_integration"
              onChange={handleChange}
              color="primary"
            />
          </div>

          <div className={classes.subscriptionItemFooter}>
            <ApiIntegration integrations={integrations} handleIntegrationChange={handleIntegrationChange} />
          </div>

        </div>


        <div className={classes.subscriptionItem}>

          <div className={classes.subscriptionItemHeader}>
            <Typography className={classes.subscriptionTitle} >Low Stock Alert Email</Typography >
            <FlexGrow />
            <Typography><b><span style={{ fontSize: '18px' }}>Free</span></b></Typography>
            <Switch
              type='checkbox'
              checked={values.low_stock_emails_enable}
              value={values.low_stock_emails_enable}
              name="low_stock_emails_enable"
              onChange={handleChange}
              color="primary"
            />
          </div>

          <div className={classes.subscriptionItemFooter}>
            <Typography>Receive email notifications every time the product's inventory falls below the "Low Stock Alert" number. </Typography>
          </div>

        </div>

        <div className={classes.subscriptionItem}>

          <div className={classes.subscriptionItemHeader}>
            <Typography className={classes.subscriptionTitle} >Emails to your customers</Typography >
            <FlexGrow />
            <Typography><b><span style={{ fontSize: '18px' }}>Free</span></b></Typography>
            <Switch
              type='checkbox'
              checked={values.emails_enable}
              value={values.emails_enable}
              name="emails_enable"
              onChange={handleChange}
              color="primary"
            />
          </div>

          <div className={classes.subscriptionItemFooter}>
            <CustomerEmails values={values}/>
          </div>

        </div>

        <div className={classes.subscriptionItem}>

          <div className={classes.subscriptionItemHeader}>
            <Typography className={classes.subscriptionTitle} >Emails to your customers with your branding</Typography >
            <FlexGrow />
            <Typography><b><span style={{ fontSize: '18px' }}>Free</span></b></Typography>
            <Switch
              type='checkbox'
              checked={values.custom_emails_enable}
              value={values.custom_emails_enable}
              name="custom_emails_enable"
              onChange={handleChange}
              color="primary"
            />
          </div>


          <div className={classes.subscriptionItemFooter}>
            <BrandingEmails values={values} handleChange={handleChange} setValues={setValues}/>
          </div>

        </div>

        {/*<div className={classes.subscriptionItem}>

          <div className={classes.subscriptionItemHeader}>
            <Typography className={classes.subscriptionTitle} >Tracking Link status Emailed</Typography >
            <FlexGrow />
            <Typography><b><span style={{ fontSize: '18px' }}>Free</span></b></Typography>
            <Switch
              type='checkbox'
              checked={values.tracking_emails_enable}
              value={values.tracking_emails_enable}
              name="tracking_emails_enable"
              onChange={handleChange}
              color="primary"
            />
          </div>

          <div className={classes.subscriptionItemFooter}>
            <TrackingEmails />
          </div>

        </div>*/}


        <div className={classes.footer}>
          <Button
            className={classes.saveButton}
            onClick={handleSubmit}
            variant="contained"
          >
            Save Changes
          </Button>
        </div>

      </div>

      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
      />

    </div>
  )
};

Subscription.propTypes = {
  className: PropTypes.string
};

export default Subscription;


