import React, {Component} from 'react'
import {connect} from 'react-redux';
import {createNewOrder} from 'actions/orderActions';
import {withStyles} from '@material-ui/core/styles';
import {Snackbar, Stepper, Step, StepLabel} from '@material-ui/core';
import P2Location from './Parts/P2Location';
import P3Method from './Parts/P3Method';
import P1SKU from './Parts/P1SKU';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';



class NewOrderForm extends Component {
    constructor(props) {
        super(props);
        this.steps = ['SKU', 'Location', 'Method'];
        this.state = {
            activeStep: 0,
        }
    }

    getStepContent(step) {
        switch(step) {
            case 0:
                return <P1SKU onChange={this.handleChange} parentState={this.state} />;
            case 1:
                return <P2Location onChange={this.handleChange} parentState={this.state} />;
            case 2:
                return <P3Method onChange={this.handleChange} parentState={this.state} />;
            default:
                throw new Error('Unknown step');
        }
    }

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    };

    handleNext = () => {
        var activeStep = this.state.activeStep + 1
        this.setState({
            activeStep: activeStep
        })
        if(activeStep === this.steps.length) {
            this.props.dispatch(createNewOrder());
        }

    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.paper}>
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={Boolean(this.props.error)}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    message={<span id="message-id">{this.props.error ? this.props.error.message : null}</span>}
                />

                <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                    {this.steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className={classes.stepContainer}>
                    {this.state.activeStep === this.steps.length ? (
                        <>
                            <Typography variant="h6" gutterBottom>
                                SKU has been added
                            </Typography>
                            <Typography variant="subtitle1">
                                Window will close shortly
                            </Typography>
                        </>
                    ) : (
                        <>
                            {this.getStepContent(this.state.activeStep)}
                            <div className={classes.buttons}>
                                {this.state.activeStep !== 0 && (
                                    <Button
                                        variant="contained"
                                        onClick={this.handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleNext}
                                    className={classes.nextButton}
                                >
                                    {this.state.activeStep === this.steps.length - 1 ? 'Create SKU' : 'Next'}
                                </Button>
                            </div>
                        </>
                    )}
                </div>


            </div>
        )
    }
}


const styles = theme => ({
    paper: {
        position: 'relative',
        marginTop: '3px',
        paddingTop: '16px',
        marginBottom: '3px',
        padding: '2px',
        paddingBottom: '32px',
        height: '100vh'
    },
    stepContainer: {
        padding: '24px',
    },
    backButton: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        margin: '16px',
    },
    nextButton: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        margin: '16px',
    },
});


const mapStateToProps = (state, ownProps) => ({
    items: state.spaceReducer.items,
    orders: state.orderReducer.orders,
    newOrderInProgress: state.orderReducer.newOrderInProgress,
    newOrderItems: state.orderReducer.newOrderItems,
    newOrderError: state.orderReducer.newOrderError,
    newOrderObject: state.orderReducer.newOrderObject,
    ...ownProps,
})

export default connect(mapStateToProps)(withStyles(styles)(NewOrderForm));
