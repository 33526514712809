import React, {memo, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import {withStyles} from '@material-ui/core/styles';
import {
  Grid, Paper, Card,
  Typography, CardContent,
  Button,
  Table,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@material-ui/core";
import {Skeleton} from '@material-ui/lab';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import Pagination from 'layouts/Pagination';
import NoRecords from 'layouts/AdminLayout/components/NoRecords';

const styles = theme => ({
  root: {
    borderRadius: '16px',
    padding: "24px 25px"
  },
  paper: {
    width: '100%',
    overflow: 'auto',
    style: {border: 'none'},
    color: '#40B5AD',
    marginTop: '50px',
    marginBottom: theme.spacing(3),
  },
  marginBottom: {
  },

  themeText: {
    color: theme.palette.adminPrimary.dark
  },
  subText: {
    color: "#6E838E"
  },
  link: {
    textDecoration: 'none',
  },
  tableHead: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 6px 6px #00000029',
    border: '0px solid #eeeeee',
    '& th': {
      fontWeight: "bold",
    },

  },
  tableFont: {
    fontWeight: '600'
  },
  hidden: {
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    }
  },
  checkboxCell: {
    marginRight: 10
  },
  pageTilesContainer: {
    display: 'flex'
  },
  pageTile: {
    border: '1px #8080803d solid',
    height: 20,
    minWidth: 20,
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 2,
    cursor: 'pointer'
  },
  navigationTile: {
    border: '1px #8080803d solid',
    height: 20,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 2,
    cursor: 'pointer'
  },
  shadow: {
    boxShadow: '0px 3px 6px #00000029'
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& .MuiTablePagination-actions': {
      // display: 'none'
    },
    '& .MuiTablePagination-input + .MuiTablePagination-caption': {
      display: 'none'
    }
  },
  buttonsContainer: {

    '& > *': {
      margin: theme.spacing(1),
    },
    margin: '5px 49px',
    float: 'right'
  },
  saveButton: {
    minWidth: "150px",
    color: '#40B5AD',
    fontWeight: 600,
    border: 2,
    boxShadow: ' 6px 6px 6px #DEE5E7',
    borderRadius: '6px',
  },
  deleteButton: {
    color: '#FF6060'
  },
  cancelButton: {
    minWidth: "150px",
    color: '#696969',
    fontWeight: 600,
  },
  dateTime: {
    letterSpacing: "0.04px!important"
  },
  orgName: {
    width: "250px"
  },
  font: {
    fontWeight: 600,
  },
  deleteIcon: {
    color: "#E69A9A"
  },
  popupText: {
    '& > *': {
      color: "#6E838E",
    },

    padding: "0 40px"
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& .MuiTablePagination-actions': {
      display: 'none'
    },
    '& .MuiTablePagination-input + .MuiTablePagination-caption': {
      display: 'none'
    }
  }
});



export function DraftInvoicesTable(props) {
  const { classes, match, invoices, pagination, loading, error, formSuccess, notification, fetchDraftInvoices, cancelInvoice } = props;

  const {id} = match.params;

  const [selectedRecord, setSelectedRecord] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const dummyData = [...Array(10)];
  const params = {}

  const handleChangePage = (event, page) => {
    params.per_page = pagination.per_page;
    params.page = page;
    fetchDraftInvoices(params);
  };

  const handleChangeRowsPerPage = event => {
    const perPage = (event.target.value === 'All') ? pagination.total_count : event.target.value;
    params.per_page = perPage;
    params.page = 1;
    fetchDraftInvoices(params);
  };

  const cancelInvoiceClick = () => {
    cancelInvoice(selectedRecord.id)
  }

  const handleAlertOpen = (record) => {
    setSelectedRecord(record)
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setSelectedRecord({})
    setOpenAlert(false);
  };

  useEffect(() => {

    fetchDraftInvoices({
      per_page: 25,
      page: 1
    });


  }, []);

  useEffect(() => {
    if(formSuccess) {
      handleAlertClose()
      fetchDraftInvoices(params);
    }

  }, [formSuccess]);

  return (
    <Card className={classes.root}>
      <CardContent >
        <Paper className={classes.paper} elevation={0}>
          <SuccessSnackbar
            open={(error || notification) ? true : false}
            error={(error) ? error.error_message || error.error || error : null}
            messageText={notification}
          />

          <Grid container className={classes.table} >


            <Table >

              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align='center' style={{fontWeight: 600}}>Id</TableCell>
                  <TableCell style={{fontWeight: 600}}>Created</TableCell>
                  <TableCell style={{fontWeight: 600}}>Type</TableCell>
                  {!id && <TableCell style={{fontWeight: 600}}>Organisation</TableCell>}
                  <TableCell style={{fontWeight: 600}}>Date From</TableCell>
                  <TableCell style={{fontWeight: 600}}>Date To</TableCell>
                  <TableCell align='right' style={{fontWeight: 600}}>Total</TableCell>
                  <TableCell align='right' style={{fontWeight: 600}}>Credit</TableCell>
                  <TableCell align='right' style={{fontWeight: 600}}>Outstanding</TableCell>
                  <TableCell align='center' style={{fontWeight: 600}}>View</TableCell>
                  <TableCell align='center' style={{fontWeight: 600}}>Delete</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ?
                  <>{dummyData.map(row => (
                    <TableRow style={{padding: 50}}>
                      <TableCell style={{width: 170}}>
                        <Skeleton animation="wave" width="100%"><Typography className={classes.font}>.</Typography></Skeleton>
                      </TableCell>
                      <TableCell style={{width: 170}}>
                        <Skeleton animation="wave" width="100%"><Typography className={classes.font}>.</Typography></Skeleton>
                      </TableCell>
                      <TableCell align='center'>
                        <Skeleton animation="wave" width="100%"><Typography >.</Typography></Skeleton>
                      </TableCell>
                      {!id && <TableCell>
                        <Skeleton animation="wave" width="100%"><Typography >.</Typography></Skeleton>
                      </TableCell>}
                      <TableCell align='center'>
                        <Skeleton animation="wave" width="100%"><Typography >.</Typography></Skeleton>
                      </TableCell>
                      <TableCell align='center'>
                        <Skeleton animation="wave" width="100%"><Typography >.</Typography></Skeleton>
                      </TableCell>
                      <TableCell align='center'>
                        <Skeleton animation="wave" width="100%"><Typography >.</Typography></Skeleton>
                      </TableCell>
                      <TableCell align='center'>
                        <Skeleton animation="wave" width="100%"><Typography >.</Typography></Skeleton>
                      </TableCell>
                      <TableCell align='center'>
                        <Skeleton animation="wave" width="100%"><Typography >.</Typography></Skeleton>
                      </TableCell>
                      <TableCell align='center' >
                        <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center">
                          <Skeleton animation="wave" variant="circle" width={40} height={40}>
                            <IconButton className={classes.iconButton}></IconButton></Skeleton>
                        </Grid>

                      </TableCell>
                      <TableCell align='center' >
                        <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center">
                          <Skeleton animation="wave" variant="circle" width={40} height={40}>
                            <IconButton className={classes.iconButton}></IconButton></Skeleton>
                        </Grid>

                      </TableCell>
                    </TableRow>
                  ))}</>
                  : <>{invoices.map(row => (

                    <TableRow
                      hover
                      key={row.id}
                    >
                      <TableCell align='center' className={classes.themeText} style={{width: "100px"}}>
                        {(row.id) ? <Link target="_blank" to={`/admin/accounting/invoices/drafts/${row.id}/details`}>{row.id}</Link> : `-`}
                      </TableCell>
                      <TableCell style={{width: "150px"}}>
                        <Typography className={classes.dateTime}>{row.created_at_time}</Typography>
                        <Typography className={classes.dateTime}>{row.created_at_date}</Typography>
                      </TableCell>
                      <TableCell >
                        {row.invoice_generation_type}
                      </TableCell>

                      {!id && <TableCell className={classes.orgName} >
                        <Link target="_blank" to={`/admin/organisations/summary/${row.organisation_id}`} className={classes.link}>
                          <Typography className={classes.themeText}>{row.organisation_name}</Typography>
                        </Link>

                        <Link target="_blank" to={`/admin/organisations/users/${row.organisation_owner_id}`}>
                          <Typography className={classes.subText}>{row.organisation_owner_name}</Typography>
                        </Link>
                      </TableCell>}
                      <TableCell >
                        {row.date_from}
                      </TableCell>

                      <TableCell >
                        {row.date_to}
                      </TableCell>

                      <TableCell align='right'>
                        ${row.charge}
                      </TableCell>

                      <TableCell align='right'>
                        ${row.credit}
                      </TableCell>

                      <TableCell align='right'>
                        ${row.balance}
                      </TableCell>

                      <TableCell align='center'>
                        <Link target="_blank" to={`/admin/accounting/invoices/drafts/${row.id}/details`}>
                          <IconButton edge="end" className={classes.shadow}><InsertDriveFileIcon /></IconButton></Link>
                      </TableCell>

                      <TableCell variant="outlined" align='center'>
                        <IconButton edge="end" className={classes.shadow} onClick={() => handleAlertOpen(row)}>
                          <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}</>}
              </TableBody>
            </Table>
            {(!invoices.length && !loading) ? <NoRecords /> : null}
          </Grid>
          <Grid justify="flex-end" container direction="row" alignItems="center">
            <CardActions className={classes.actions}>
              <Pagination pagination={pagination} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />

            </CardActions>

          </Grid>


          <Dialog
            open={openAlert}
            disableBackdropClick={true}
            onClose={() => handleAlertClose()}
            aria-labelledby="cancel-invoice-title"
            aria-describedby="cancel-invoice-text"
          // className={classes.dialog}
          >
            <DialogTitle id="cancel-invoice-title"><Typography className={classes.font}>Delete</Typography></DialogTitle>
            <DialogContent>
              <DialogContentText id="cancel-invoice-text" className={classes.popupText}>
                <Typography>Are you sure that you want to cancel this invoice?</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.buttonsContainer} spacing={2}>
                <Button className={classes.saveButton + " " + classes.deleteButton}
                  variant="outlined"
                  onClick={cancelInvoiceClick}
                >DELETE</Button>
                <Button variant="text" onClick={() => handleAlertClose()} className={classes.cancelButton}>
                  CANCEL
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>

        </Paper>
      </CardContent >
    </Card>
  );
}
DraftInvoicesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default memo(withStyles(styles)(DraftInvoicesTable));





