import React, { useState, useEffect, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { reverseUserRoute } from "routes";
import store from "index";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateOrderInbound } from "actions/orderActions";
import { fetchSuppliers } from "actions/supplierActions";
import { fetchOrderByID } from "actions/orderActions";

// Material UI
import {
  makeStyles,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";

// Project Components
import { Page, HeaderAndSubtitle } from "components";
import keepspaceApi from "services/keepspaceApi";

// Local Components
import Products from "../components/1Products";
import Destination from "../components/2Destination";
import AdditionalDetails from "../components/3AdditionalDetails";

import { ValidatorForm } from "react-material-ui-form-validator";
import { UID } from "AppConstants";
import { get } from "lodash";

export const EditInbound = (props) => {
  const classes = useStyles();
  const [orderResult, setOrderResult] = useState({});

  const [pendingOrderSKUs, setPendingOrderSKUs] = useState([]);
  const [editObject, setEditObject] = useState({});
  const [shippingCarriers, setShippingCarriers] = useState([]);

  const updateOrderLoading = useSelector(
    (state) => state.orderReducer.updateOrderLoading
  );

  const updateOrderErrors = useSelector(
    (state) => state.orderReducer.updateOrderErrors || state.orderReducer.error
  );

  const suppliers = useSelector((state) => state.suppliersReducer.suppliers);
  let order = useSelector((state) => state.orderReducer.fetchedOrder);

  const dispatch = useDispatch();
  const { match, countries, updateCountries, warehouses } = props;
  const { id } = match.params;
  const editableStatuses = [
    "info_received",
    "no_stock",
    "amending",
    "unconfirmed",
    "awaiting_arrival",
  ];

  let inputRef = React.createRef("form");

  const submit = (event) => {
    event.preventDefault();
    let booking = editObject;
    Object.keys(booking).map(k => booking[k] = typeof booking[k] == 'string' ? booking[k].trim() : booking[k]);
    let items = pendingOrderSKUs.map((pendingOrderSKU) => ({
      id: pendingOrderSKU.item.id,
      quantity: pendingOrderSKU.quantity,
    }));
    dispatch(updateOrderInbound(booking, items)).then((result) => {
      if (result) {
        // result is null when in error
        setOrderResult(result);
        return true;
      }
      else return false
    });
  };

  const DestinationInputs = [
    {
      id: "company_name",
      label: "Supplier Company",
      required: false,
    },
    {
      id: "contact_name",
      label: "Suppliers Contact Name",
      required: true,
    },
    {
      id: "email",
      label: "Email Address",
      required: true,
    },
    {
      id: "contact_phone",
      label: "Phone Number",
      required: true,
    },
    {
      id: "address",
      label: "Address Line 1",
      required: true,
    },
    {
      id: "address2",
      label: "Address Line 2",
      required: false,
    },
    {
      id: "city", // postcode is stored in the city column
      label: "Postcode",
      required: true,
    },
    {
      id: "suburb",
      label: "Suburb",
      required: true,
    },
    {
      id: "state",
      label: "State",
      required: true,
    },
    {
      id: "country",
      label: "Country",
      required: true,
      type: "native-select",
      options: countries,
    },
  ];

  const AdditionalDetailsInputs = [
    {
      id: "warehouse_id",
      label: "KeepSpace Warehouse",
      required: true,
      type: "native-select",
      options: warehouses,
    },
    {
      id: "shipper_id",
      label: "Carrier Company",
      type: "native-select",
      options: shippingCarriers,
    },
    {
      id: "reference",
      label: "Waybill Number (Reference)",
      required: true,
    },
    {
      id: "tracking_id",
      label: "Tracking Number",
      required: true,
    },
    {
      id: "eta",
      label: "Estimated Arrival Date",
      required: true,
      type: "date",
      minDate: new Date(),
    },
  ];

  const AutosuggestFields = {
    display: {
      title: {
        label: "Display name: ",
        value: "display_name",
      },
      subtitle: [
        {
          label: "Company name: ",
          value: "name",
        },
        {
          label: "Contact name: ",
          value: "contact_name",
        },
      ],
    },
    match: ["contact_name", "name", "display_name"],
    value: "name",
    placeholder: "Search suppliers",
  };

  useEffect(() => {
    dispatch(fetchOrderByID(id));
  }, []);

  useEffect(() => {
    if (suppliers.length === 0) dispatch(fetchSuppliers());
  }, [order.id, suppliers.length]);

  // fetch valid shipping carriers
  useEffect(() => {
    let unmounted = false;
    if (shippingCarriers.length === 0) {
      keepspaceApi
        .getShippingCarriers()
        .then((json) => {
          if (json.shipping_carriers && !unmounted)
            setShippingCarriers(json.shipping_carriers);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return () => {
      unmounted = true;
    };
  }, [shippingCarriers.length]);

  useEffect(() => {
    if (order.booking_items === undefined) return;

    // set EditObject from order object
    let orderObject = { id: id, supplier_id: get(order, 'supplier_id', null) };
    DestinationInputs.forEach(
      (attr) => (orderObject[attr.id] = order[attr.id])
    );
    AdditionalDetailsInputs.forEach(
      (attr) => (orderObject[attr.id] = order[attr.id])
    );
    if (orderObject.country != "") {
      countries.forEach(function(country, index) {
        if (orderObject.country !== "" && country.text == orderObject.country) {
          orderObject.country = country.value;
        }
      });
    }

    if (orderObject.shipper_name == "Australia Post") {
      orderObject.shipper_id = order.shipping_carrier_og;
    }

    setEditObject(orderObject);

    // set PendingOrderSKU from order object
    let bookingItems = order.booking_items.map((booking_item) => {
      let orderSku = {
        item: booking_item.sku || booking_item.item_id,
        quantity: booking_item.quantity_ordered,
      };
      return orderSku;
    });
    setPendingOrderSKUs(bookingItems);
  }, [shippingCarriers, countries.length, id, order]);

  if (
    order.batch_id ||
    (order.status && !editableStatuses.includes(order.status))
  ) {
    return (
      <Redirect
        to={{
          pathname: `/user/${UID}/orders/summary/${id}/details/overview`,
          errorMessage: "You cannot edit this order",
        }}
      />
    );
  }
  const quantityVariation = (id, quantityVariation) => {
    let newBookingItems = pendingOrderSKUs.map((bookingItem) =>
      bookingItem.item && bookingItem.item.id === id
        ? {
            item: bookingItem.item,
            quantity: bookingItem.quantity + quantityVariation,
          }
        : bookingItem
    );
    setPendingOrderSKUs(
      newBookingItems.filter(
        (bookingItem) =>
          bookingItem.quantity > 0 || bookingItem.quantity === null
      )
    );
  };

  const addBookingItems = (arrayOfProducts) => {
    let newArray = pendingOrderSKUs;

    arrayOfProducts.forEach((product) => {
      newArray = addProducts(newArray, product.item_id, product.item_quantity);
      return newArray;
    });

    setPendingOrderSKUs(newArray);
  };

  const addProducts = (array, id, qty) => {
    let newBookingItem = true;
    let bookingItemObj = array
      ? array.find(
          (bookingItem) => bookingItem.item && bookingItem.item.id === id
        )
      : null;
    if (bookingItemObj) newBookingItem = false;

    if (!newBookingItem) {
      let bookingItemsUpdatedQty = array.map((bookingItem) =>
        bookingItem.item && bookingItem.item.id === id
          ? { item: bookingItem.item, quantity: qty + bookingItem.quantity }
          : bookingItem
      );
      return bookingItemsUpdatedQty;
    }

    const item = store
      .getState()
      .spaceReducer.items.find((item) => item.id === id);

    if (item) {
      let newItem = { item: item, quantity: qty };
      return [...array, newItem];
    }
  };

  const handleSelectSupplier = (supplier) => {
    const fields = DestinationInputs.map((h) => h.id);
    let updatedObject = Object.assign({}, editObject);
    fields.forEach((key) => {
      switch (key) {
        case "company_name":
          updatedObject[key] = supplier.name;
          break;
        case "city":
          updatedObject[key] = supplier.postcode;
          break;
        case "address":
          updatedObject[key] = supplier.address_line_1;
          break;
        case "address2":
          updatedObject[key] = supplier.address_line_2;
          break;
        case "contact_phone":
          updatedObject[key] = supplier.phone;
          break;
        default:
          updatedObject[key] = supplier[key];
          break;
      }
    });
    setEditObject(updatedObject);
  };

  const handleChange = (event, name, val) => {
    setEditObject({
      ...editObject,
      [name || event.target.id]: val || event.target.value,
    });
  };


  return (
    // <Animate in style={{ transformOrigin: '0 0 0' }} >
    <Page title="Edit Inbound" className={classes.root}>
      {orderResult.id ? (
        <Redirect
          to={`${reverseUserRoute("Orders")}summary/${orderResult.id}/details/`}
        />
      ) : null}

      <HeaderAndSubtitle
        header="Order / Summary"
        subtitle="Edit Inbound Order"
      />

      {/* Form Part 1 */}
      <Products
        key="products"
        pendingOrderSKUs={pendingOrderSKUs}
        quantityVariation={quantityVariation}
        addBookingItems={addBookingItems}
        orderType="inbound"
        formState={editObject}
      />

      <ValidatorForm
        onSubmit={submit}
        onError={(errors) => console.log(errors)}
        ref={inputRef}
      >
        {/* Form Part 2 */}
        <Destination
          key="destination"
          formInputs={DestinationInputs}
          formState={editObject}
          formErrors={updateOrderErrors}
          onChange={handleChange}
          orderType="inbound"
          searchInput={suppliers}
          handleSelect={handleSelectSupplier}
          autosuggestFields={AutosuggestFields}
          updateCountries={updateCountries}
        />

        {/* Form Part 3 */}
        <AdditionalDetails
          key="additionalDetails"
          formInputs={AdditionalDetailsInputs}
          formState={editObject}
          formErrors={updateOrderErrors}
          onChange={handleChange}
        />

        <div className={classes.errors}>
          {updateOrderErrors ? (
            <Typography color="error">
              {updateOrderErrors.messages ||
                Object.keys(updateOrderErrors)
                  .map((err) => `${err}: ${updateOrderErrors[err]}`)
                  .join("\n")}
            </Typography>
          ) : null}
        </div>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => props.history.goBack()}
          style={{ marginRight: 10 }}
          disabled={updateOrderLoading}
        >
          <Typography color="inherit">Cancel</Typography>
        </Button>
        {/* Submit */}
        <div className={classes.wrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={updateOrderLoading}
          >
            <Typography color="inherit">Save changes</Typography>
          </Button>
          {updateOrderLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </ValidatorForm>
    </Page>
    // </Animate>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "inline",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  errors: {
    marginTop: 20,
    marginBottom: 40,
  },
}));

export default EditInbound;
