import React, {lazy} from "react";
import {Redirect} from "react-router-dom";
import AdminDemo from "../views/admin/AdminDemo";

export const adminRoutes = [
    {
        path: '/admin',
        exact: true,
        component: () => <Redirect to='/admin/test1'/>
    },
    {
        path: '/admin/test1',
        exact: true,
        component: AdminDemo
    },
    {
        path: '/admin/test',
        exact: true,
        component: AdminDemo
    },
    {
        component: () => <Redirect to="/errors/error-404"/>
    }
];

export const adminAuthRoutes = [
    {
        path: '/admin/auth/login',
        exact: true,
        component: lazy(() => import('layouts/AdminAuthLayout')),
        //component: AuthLayout
    },

    {
        component: () => <Redirect to="/errors/error-404"/>
    }
]