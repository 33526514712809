import React from 'react';

import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 500,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    paddingTop: 20
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'center'
  },
  content: {
    paddingTop: 20,
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));



const OrganisationUserInvitation = props => {

  const { user, handleClose, handleResend, handleCancel} = props;
  const classes = useStyles();

  if (!user.id) {
    return null;
  }

  return (
    <Modal
      onClose={handleClose}
      open={user.id ? true : false}
    >
      <Card
        className={classes.root}
      >
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h4"
            >
              Invitation
            </Typography>

            <div className={classes.content}>
              Invitation sent on {moment(user.invitation_sent_at).format('DD/MM/YY')} by {user.assignor.email}
            </div>  

          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              onClick={handleResend}
            >
              Resend Invitation
            </Button>
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              onClick={() => handleCancel(user)}
            >
              Cancel Invitation
            </Button>
          </CardActions>
      </Card>
    </Modal>
  );
};

OrganisationUserInvitation.defaultProps = {
  open: false,
  onClose: () => {}
};

export default OrganisationUserInvitation;





