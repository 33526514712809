 /**
   * @description this hook will restrict the call back fires once
   * @param {*} cb 
   * @returns 
*/
  import { useState } from "react";

  export const useCbOnce = (cb) => {
    const [called, setCalled] = useState(false);
    
    return (e) => {
      if (!called) {
        setCalled(cb(e));
      }
    }
  }