import {
    FETCH_ADMIN_ORG_PRICING_PROPOSALS_BEGIN,
    FETCH_ADMIN_ORG_PRICING_PROPOSALS_SUCCESS,
    FETCH_ADMIN_ORG_PRICING_PROPOSALS_FAILURE,
    GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_BEGIN,
    GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_SUCCESS,
    GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_FAILURE,
    UPDATE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS,
    UPDATE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE,
    DELETE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS,
    DELETE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE,
    CLEAR_ADMIN_ORG_PRICING_PROPOSALS,
    UPDATE_ADMIN_ORG_PRICING_PROPOSAL_BEGIN,
    CLEAR_ADMIN_ORG_PRICING_PROPOSALS_FILTER,
    SET_ADMIN_ORG_PRICING_PROPOSALS_FILTER,
    CLEAR_PRICING_PROPOSAL_NOTIFICATION
} from 'actions/admin/OrgPricingProposalsActions';

const initialState = {
    orgUsers: [],
    loading: false,
    error: null,
    notification: null,
    dummyData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    pagination: {},
    orgPricingProposalDetail: {},
    formSuccess: false,
    activeFilter: {
        page: 1,
        per_page: 25,
        organisation_id: null,
        active: null
    },
    magicUrl: null,
    magicLoading: null
}

export default function adminOrgPricingProposalReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_ADMIN_ORG_PRICING_PROPOSALS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                formSuccess: false,
                notification: null
            };
        case FETCH_ADMIN_ORG_PRICING_PROPOSALS_SUCCESS:
            return {
                ...state,
                loading: false,
                orgUsers: action.data.users,
                pagination: action.data.pagination,
                filters: action.data.filters
            };
        case FETCH_ADMIN_ORG_PRICING_PROPOSALS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                orgUsers: []
            };
        case GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                magicUrl: null,
                formSuccess: false,
                notification: null
            };

        case GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                orgPricingProposalDetail: action.data
            };

        case GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case UPDATE_ADMIN_ORG_PRICING_PROPOSAL_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                magicUrl: null,
                formSuccess: false,
                notification: null
            };
        case UPDATE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS:

            return {
                ...state,
                loading: false,
                formSuccess: true,
                orgPricingProposalDetail: action.data
            }
        case UPDATE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case DELETE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS:
            return {
                ...state,
                formSuccess: true,
                loading: false,
                notification: action.msg
            };
        case DELETE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case CLEAR_ADMIN_ORG_PRICING_PROPOSALS:
            return {
                ...initialState
            }
        case SET_ADMIN_ORG_PRICING_PROPOSALS_FILTER:
            return {
                ...state,
                activeFilter: {...state.activeFilter, ...action.payload.filters}
            }
        case CLEAR_ADMIN_ORG_PRICING_PROPOSALS_FILTER:
            return {
                ...state,
                activeFilter: initialState.activeFilter
            }
        case CLEAR_PRICING_PROPOSAL_NOTIFICATION:
            return{
                ...state,
                error:null,
                notification:null
            }
        default:
            return state
    }
}
