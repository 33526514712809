import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { ArrowBackIos, Edit as EditIcon } from "@material-ui/icons";
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import {UID} from 'AppConstants';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start'
    }
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: 30
  },
  buttonIcon: {
    paddingRight: 5
  }

}));

const Header = props => {
  const { order, onGoBack, cancelOrders, disabled } = props;
  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <div className={classes.title}>
          <Button
            size="small"
            onClick={onGoBack}
            variant="outlined"
            style={{marginBottom: '10px', color: '#717273', fontSize: 12}}
          >
            <ArrowBackIos style={{height: 10}}/>
            Back
          </Button>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            ORDERS/SUMMARY
          </Typography>
          <Typography
            component="h1"
            variant="h4"
          >
            {order.booking_type.toUpperCase()} {order.id}
          </Typography>
        </div>
        <div className={classes.buttonDiv}>
          <Button component={Link} to={`/user/${UID}/orders/summary/${order.id}/edit_${order.booking_type}`}  color="primary" variant="contained" disabled={disabled} >
            <EditIcon className={classes.buttonIcon} />
            Edit
          </Button>
          <Button
            style={disabled? {marginLeft: 10} : {color: 'rgba(255, 0, 0, 0.56)', borderColor: 'rgba(255, 0, 0, 0.56)', marginLeft: 10} }
            onClick={cancelOrders}
            variant="outlined"
            disabled={disabled}
            size="small"
          >
            Cancel Order
          </Button>
        </div>

      </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
