import { getStorageData } from "./services/admin/adminAuth";
import { useTheme, useMediaQuery } from '@material-ui/core';


export const authUrl = process.env.REACT_APP_API_AUTH;
export const apiPath = process.env.REACT_APP_API_URL;
export const apiPathV2 = process.env.REACT_APP_API_URL_V2;
export const adminApiPath = process.env.REACT_APP_ADMIN_URL;
export const adminApiGenPath = process.env.REACT_APP_ADMIN_URL_GEN;
//TODO : adding this to push my changes on staging, for now , assuming the env on staging server will not be having REACT_APP_PUB_URL;
export const pubApiPath = process.env.REACT_APP_PUB_URL;

export let UID = window.localStorage.getItem('organisation-uid');
if(!UID && window.location.pathname.split('/')[1] === 'user'){
    UID = window.location.pathname.split('/')[2];
}

export const updateAuthHeaders = () => {
    UID = window.localStorage.getItem('organisation-uid');
    return authHeaders = {
        'access-token': window.localStorage.getItem('access-token'),
        'token-type': window.localStorage.getItem('token-type'),
        'client': window.localStorage.getItem('client'),
        'expiry': window.localStorage.getItem('expiry'),
        'uid': window.localStorage.getItem('uid'),
        'organisation-uid': window.localStorage.getItem('organisation-uid'),
    }
}

export const updateAdminAuthHeaders = () => {

    let admin = getStorageData();
    if (!admin) {
        return {};
    }

    return adminAuthHeaders = {
        'access-token': admin['accessToken'],
        'token-type': admin['tokenType'],
        'client': admin['client'],
        'expiry': admin['expiry'],
        'uid': admin['uid'],
    }
}

export let authHeaders = updateAuthHeaders();
export let adminAuthHeaders = updateAdminAuthHeaders();
export let noUidPages = ['profile', 'getting-started', 'changelog'];

