// React
import React, {Suspense} from 'react';
import {createBrowserHistory} from 'history';

// Redux
import { connect } from 'react-redux';
import { fetchFlags } from 'actions/featureFlagActions';

// Vendor
import Rollbar from 'rollbar';
import MomentUtils from '@date-io/moment';
//import DateFnsUtils from '@date-io/date-fns';

// Router
import {Router, Redirect, Route} from "react-router-dom"
import {renderRoutes} from 'react-router-config';
import {routes} from './routes';

// Material UI
import theme from './theme';
import themeAdmin from './theme/themeAdmin';
import {ThemeProvider} from '@material-ui/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
// KeepSpace Components
import { CubeGrid } from 'components/Loading';
import { apiPath, noUidPages } from './AppConstants';

import {fetchAdmin} from "./actions/admin/authenticationActions";
import {fetchOrganisations, setCurrentOrganisation} from "./actions/organisationActions";
import {signOutUser} from 'redux-token-auth-config';
import Loader from 'layouts/AdminLayout/components/Loader';
import {removeUserDetails} from "./actions/userActions";


const mapStateToProps = (state) => ({
    reduxTokenAuth: state.reduxTokenAuth,
    admin: state.adminAuthReducer,
    isLoading: state.reduxTokenAuth.currentUser.isLoading,
    organisationUid: window.localStorage.getItem('organisation-uid'),
    organisations: state.organisationsReducer.organisations,
    organisationsLoading: state.organisationsReducer.loading,
    organisationsError: state.organisationsReducer.error,
    currentOrganisation: state.organisationsReducer.currentOrganisation,
    userLoading: state.usersReducer.loading,
})

const mapDispatchToProps = dispatch => ({
    signOutUser: () => dispatch(signOutUser()),
    checkFeatures: () => dispatch(fetchFlags()),
    fetchAdmin: () => dispatch(fetchAdmin()),
    fetchOrganisations: () => dispatch(fetchOrganisations()),
    setCurrentOrganisation: (organisation) => dispatch(setCurrentOrganisation(organisation)),
})

const history = createBrowserHistory();

export var logger; // Rollbar

class App extends React.PureComponent {

    constructor(props) {
        super(props);
        console.log('>>> process.env.REACT_APP_PUB_URL >>>', process.env.REACT_APP_PUB_URL)

        if(process.env.NODE_ENV !== 'development') {
            logger = new Rollbar({
                accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
                captureUncaught: true,
                captureUnhandledRejections: true,
                verbose: true,
                environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
                payload: {
                    person: {
                        id: this.props.reduxTokenAuth.currentUser.attributes.id,
                        email: this.props.reduxTokenAuth.currentUser.attributes.email,
                    }
                }
            })
        }

        this.state = {
            currentOrgnisationValid: false,
            organisationValidationFinished: false,
            username: null,
            apiPath: apiPath,
            showLoader: false,
            rollbar: logger,
            adminLoggedIn: false,
            loadedAdmin: false, // it doesnt determine whether or not admin is logged in. it only fetches the data from localstorage to find admin present or not
            uidMismatch: false
        }

        this.updateRollbarConfig = this.updateRollbarConfig.bind(this)
        this.setOrganisation = this.setOrganisation.bind(this)
    }

    componentDidMount = async () => {
        // fetch adminUser from storage
        if(window.location.pathname.split('/')[1] === 'admin') {
            await this.props.fetchAdmin();
        }
        if(window.location.pathname.split('/')[1] === 'user') {
            this.validateUid();
            this.props.checkFeatures();
        }
        this.setState({ loadedAdmin: true, adminLoggedIn: this.props.admin.isLoggedIn })


    }

    async componentDidUpdate() {
        const uid = (window.location.pathname.split('/')[1] === 'user') ? window.location.pathname.split('/')[2] : null;
        if(uid === ":uid") {
            const uid = window.localStorage.getItem('uid');
            if(uid) {
                await this.logOutUser();
            }
            return;
        }
        if(this.state.uidMismatch && this.state.organisationValidationFinished) {
            let userOrganisationNotFound = true;

            if(noUidPages.includes(uid)) return

            this.props.organisations.map(organisation => {
                if(organisation.uid === uid) {
                    this.props.setCurrentOrganisation(organisation)
                    userOrganisationNotFound = false
                    this.setState({ uidMismatch: false });

                    const url = window.location.href;
                    const updatedUrl = url.replace(uid, organisation.uid);
                    window.location.assign(updatedUrl);
                    return true
                }
            })

            if(userOrganisationNotFound) {
                this.setState({uidMismatch: false});
                await this.logOutUser();

            }
        }
    }

  logOutUser = async () => {
    try {
      await this.props.signOutUser();
    } catch (e) {
    } finally {
        removeUserDetails();
    }

  }

    validateUid = () => {
        const url = window.location.pathname;
        const uid = url.split('/')[2];
        if(noUidPages.includes(uid)) return

        if(uid != this.props.organisationUid) {
            this.setState({uidMismatch: true});
        }
    }

    removeLoader() {
        if(this.state.showLoader) {

            setTimeout(() => {
                this.setState({
                    showLoader: false
                })
            }, 2000);
        }
    }

    updateRollbarConfig() {

        if(process.env.NODE_ENV !== 'development') {
            // We don't want rollbar in development mode, it restricts the devtools ability to determine which line raised an error message
            this.state.rollbar.configure({
                environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
                payload: {
                    person: {
                        id: this.props.reduxTokenAuth.currentUser.attributes.id,
                        email: this.props.reduxTokenAuth.currentUser.attributes.email,
                    }
                }
            })
        }
    }

    updateSlaaskConfig() {
        setTimeout(() => {
            console.log("Identifying Contact in Slaask");

            try {
                window._slaask.identifyContact();
            } catch(error) {
                console.warn('Slaask could not identify contact', error)
            }

        }, 5000)
    }


    setOrganisation() {
        if(!this.props.currentOrganisation.uid) {
            let userOrganisationNotFound = true

            this.props.organisations.map(organisation => {
                if(organisation.uid === this.props.organisationUid) {
                    this.props.setCurrentOrganisation(organisation)
                    userOrganisationNotFound = false
                    return true
                }
            })

            if(userOrganisationNotFound) {
                return <Redirect to="/auth/login" />
            }
        }
    }

    validateOrganisation = async () => {
        let valid = false
        // fetch organisations
        if(this.props.organisations.length === 0 && !this.props.organisationsLoading && !this.props.organisationsError) {
            this.props.fetchOrganisations()
                .then(result => {
                    valid = result.some(organisation => organisation.uid === this.props.organisationUid)
                    this.setState({ currentOrgnisationValid: valid })
                    this.setState({ organisationValidationFinished: true })
                })
                .catch(error => console.log('error', error))
        } else if(!this.props.organisationsLoading) {
            valid = this.props.organisations.some(organisation => organisation.uid === this.props.organisationUid)
            this.setState({ currentOrgnisationValid: valid })
            this.setState({ organisationValidationFinished: true })
        }
        return valid
    }

    authoriseUser() {
        if(this.props.reduxTokenAuth.currentUser.hasVerificationBeenAttempted) {
            if(this.props.reduxTokenAuth.currentUser.isSignedIn && (!Boolean(this.props.organisationUid) || this.props.organisationUid === 'null')) {
                return <Redirect to="/auth/organisations" />
            } else if(this.props.reduxTokenAuth.currentUser.isSignedIn && Boolean(this.props.organisationUid)) {
                this.validateOrganisation()
                    .then(result => {
                        if(!this.state.currentOrgnisationValid && this.state.organisationValidationFinished) {
                            this.removeLoader()

                            return <Redirect to="/auth/login" />
                        } else if(this.state.currentOrgnisationValid && this.state.organisationValidationFinished) {
                            this.setOrganisation()
                            this.updateRollbarConfig()
                            this.updateSlaaskConfig()
                            this.removeLoader()
                        }
                    })
                    .catch(e => {
                        // console.log('>>>>>> authorise user error ', e);
                        this.logOutUser()
                            .then(res => {
                            })
                            .catch(e => console.log('error while logging out user in catch of authorise user', e));
                    });

            } else {
                this.removeLoader();
                removeUserDetails();
            }
        } else {
            this.setState({ showLoader: true })
        }
    }

    authoriseAdmin() {

        if(this.props.admin.isLoggedIn) {
            this.removeLoader();
            return <Redirect to={{
                pathname: window.location.pathname,
                state: {referrer: window.location.pathname},
                search: window.location.search
            }} />
        } else if(window.location.pathname.split('/')[2] !== 'auth') {
            this.removeLoader();
            return <Redirect to={{
                pathname: "/admin/auth/login",
                state: {referrer: window.location.pathname}
            }} />
        }
    }

    render() {
        return (
            <div className="App">
                {/* This is the loading animation that appears on first open */}
                <CubeGrid
                    showLoader={this.state.showLoader}
                    isLoading={this.props.reduxTokenAuth.currentUser.isLoading || this.props.admin.isLoading || !this.state.loadedAdmin}
                />
                {
                    this.state.loadedAdmin &&
                    (<ThemeProvider theme={window.location.pathname.includes("admin")?themeAdmin:theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>

                            <Router history={history}>

                                {/*
                               If they're visiting /user/* run the auth and configuration routines
                               These functions should halt an unauthorised request, otherwise the views will be rendered
                            */}
                                {window.location.pathname.split('/')[1] === 'user' ? this.authoriseUser() : null}
                                {window.location.pathname.split('/')[1] === 'admin' ? this.authoriseAdmin() : null}
                                <Suspense fallback={<Loader />}>
                                    {renderRoutes(routes)}
                                </Suspense>
                            </Router>

                        </MuiPickersUtilsProvider>
                    </ThemeProvider>)
                }

            </div>
        )
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(App);

