import React from 'react';

import {
  Dialog, DialogActions, DialogContent, Typography, Button,
  DialogContentText, DialogTitle, List, ListItem, ListItemText,
  TextField, FormControl, Select, MenuItem, InputLabel, makeStyles
} from '@material-ui/core';

const ItemPriceDialog = (props) => {
  const { open, items, handleClose, itemPricingChange, pricingErrorText, defaultSaleCurrency } = props;
  const classes = useStyles();

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth='md'
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h4" component="h3">
          Commercial Invoice
        </Typography>
      </DialogTitle>

      <DialogContent dividers={true}>
        <DialogContentText>
          <List dense={true}>
            {items.map((item, index) => !item.item ? null : (

              <ListItem>
                <ListItemText className={classes.itemPricingSection}>
                  {index + 1}. {item.item.title}
                </ListItemText>

                <ListItemText className={classes.itemPricingSection}>
                  <TextField label="Sale price" className={classes.formControl} onChange={(e) => itemPricingChange(e.target.value, item.item.id, "price")} defaultValue={item.sale_price} />
                </ListItemText>
                <ListItemText className={classes.itemPricingSection}>
                  <TextField label="Sale currency" className={classes.formControl} defaultValue={item.sale_price || defaultSaleCurrency} disabled={true} />
                </ListItemText>
              </ListItem>


            ))}

          </List>
          {pricingErrorText && <DialogContentText className={classes.errorText}>{pricingErrorText}</DialogContentText>}
        </DialogContentText>
        <div className={classes.subtitle}>
          <Typography>It is a legal requirement to fill out a commercial invoice for all international shippings.</Typography>
          <Typography>If not specified otherwise, the Sale Price (provided by Ecommerce store) or Retail cost (from Keepspace Product page) will be used to declare products' values.</Typography>
          <Typography>Here you can re-adjust these values if the product was sold for a discounted price, higher price, in a foreign currency etc.</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  itemPricingSection: {
    width: '120px',
    paddingBottom: '15px',
    margin: '10px 10px'
  },
  formControl: {
    width: '100%'
  },
  errorField: {
    borderColor: 'red'
  },
  errorText: {
    color: 'red',
    textAlign: "center"
  },
  menuPaper: {
    maxHeight: 250
  },
  subtitle: {
    padding: '10px 18px',
    fontStyle: 'italic'
  }
}))

export default ItemPriceDialog
