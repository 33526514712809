import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
// Material UI
import theme from '../.././theme';
//import '../../index.css';
import KeepSpaceIcon from 'components/Icons/KeepSpaceIcon';
import { ThemeProvider } from '@material-ui/styles';
import { Typography, Button, useTheme, useMediaQuery } from '@material-ui/core';

import {
    Paper,
    Grid
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    paper: {
        border: 'none',
        boxShadow: 'none',
        padding: '50px',
        height:'100vh',
        width:'100%',
        textAlign:'center'
    },
    mainContent:{
        margin:'20vh 0'
    },
    ksLogo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
        color: '#1b30e1',
        marginBottom:40
    },
    ksName: {
        fontSize: 45,
        marginLeft: 20,
    },
    heading:{

    },
    errColor: {
        color: 'red',

    },

    forgotPass: {
        marginLeft: 'auto',

    },

}
))



const Maintenance = () => {
    const classes = useStyles();

    return (
        <div>



            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} >
                    <Paper className={classes.paper}>
                        <section className="left-section full-height">

                            <a className="logo" href="#">
                                <div className={classes.ksLogo}>

                                    <KeepSpaceIcon />
                                    <div className={classes.ksName}>KeepSpace</div>
                                </div>
                            </a>

                                
                                        <div className={classes.mainContent}>
                                            <Typography className="heading" variant="h4" component="h3" gutterBottom>
                                                Under Construction
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary" gutterBottom>Our website is currently undergoing scheduled maintenance.
                                           <br/> We Should be back shortly. Thank you for your patience.
                                            </Typography>


                                        </div>
                                   

      

                        </section>

            </Paper>
                </Grid>
               
            </Grid>

        </div>
    )
}


export default Maintenance;
