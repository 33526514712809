import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  rows: {
    marginTop: 10
  }
}));



const SupplierEdit = props => {

  const { open, onClose, className, handleSave, handleChange, values, contactFields, title, addressFields, error, ...rest } = props;
  const classes = useStyles();
  let inputRef = React.createRef("form");

  if (!open) {
    return null;
  }

  // let moreFields = null
  const moreFields =  (fields, title) =>

      <Grid
        item
        md={6}
        xs={12}
      >
        <Typography gutterBottom>{title}</Typography>
        {fields.map((column, i) =>
          <TextValidator
            key={`${column.value}_${i}`}
            className={classes.rows}
            validators={column.required}
            errorMessages={column.errorMessages}
            id={column.value}
            name={column.value}
            label={column.label}
            fullWidth
            value={values[column.value] || ''}
            onChange={handleChange}
            variant="outlined"
            SelectProps={{ native: column.select }}
            select={ Boolean(column.select) }
            error={ error ? Boolean(error.response.data[column.value]) : false }
            helperText={error ? error.response.data[column.value] : false}
          >
            { column.options.map(option =>
               <option
                 key={`${option[column.optionValue]}_${i}`}
                 value={option[column.optionValue]}
               >
                 {option.title || option.text}
               </option>
             )}
          </TextValidator>
        )}
      </Grid>


  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
          <ValidatorForm
              ref={inputRef}
              onSubmit={handleSave}
              onError={errors => console.log(errors)}
          >
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h4"
            >
              {title}
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
             {moreFields(contactFields, 'Contact Details')}
             {moreFields(addressFields, 'Address Details')}
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
            >
              Save
            </Button>
          </CardActions>
        </ValidatorForm>
      </Card>
    </Modal>
  );
};

SupplierEdit.displayName = 'SupplierEdit';

SupplierEdit.propTypes = {
  className: PropTypes.string,
  values: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

SupplierEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default SupplierEdit;





