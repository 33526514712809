import adminApi from 'services/admin/adminApi';
/*----------------materials--------------------------*/
export const FETCH_ADMIN_ORG_PRICING_PROPOSALS_BEGIN = 'FETCH_ADMIN_ORG_PRICING_PROPOSALS_BEGIN';
export const FETCH_ADMIN_ORG_PRICING_PROPOSALS_SUCCESS = 'FETCH_ADMIN_ORG_PRICING_PROPOSALS_SUCCESS';
export const FETCH_ADMIN_ORG_PRICING_PROPOSALS_FAILURE = 'FETCH_ADMIN_ORG_PRICING_PROPOSALS_FAILURE'

export const DELETE_ADMIN_ORG_PRICING_PROPOSAL = 'DELETE_ADMIN_ORG_PRICING_PROPOSAL'
export const DELETE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS = 'DELETE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS'
export const DELETE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE = 'DELETE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE'

export const GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_BEGIN = 'GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_BEGIN';

export const GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_SUCCESS = 'GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_SUCCESS';
export const GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_FAILURE = 'GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_FAILURE';

export const UPDATE_ADMIN_ORG_PRICING_PROPOSAL_BEGIN = 'UPDATE_ADMIN_ORG_PRICING_PROPOSAL_BEGIN';
export const UPDATE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS = 'UPDATE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS';
export const UPDATE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE = 'UPDATE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE';

export const CLEAR_ADMIN_ORG_PRICING_PROPOSALS = 'CLEAR_ADMIN_ORG_PRICING_PROPOSALS';

export const SET_ADMIN_ORG_PRICING_PROPOSALS_FILTER = 'SET_ADMIN_ORG_PRICING_PROPOSALS_FILTER';
export const CLEAR_ADMIN_ORG_PRICING_PROPOSALS_FILTER = 'CLEAR_ADMIN_ORG_PRICING_PROPOSALS_FILTER';

export const CLEAR_PRICING_PROPOSAL_NOTIFICATION = 'CLEAR_PRICING_PROPOSAL_NOTIFICATION';
/*--------------------PRICING_PROPOSALs----------------------------*/
export function fetchAdminOrgPricingProposals(params) {
    return dispatch => {
        dispatch(fetchAdminOrgPricingProposalsBegin());//calling this action to show loading
        return adminApi.fetchAdminOrgPricingProposals(params)// calling list of PRICING_PROPOSALs
            .then(json => {

                dispatch(fetchAdminOrgPricingProposalsSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);
                dispatch(fetchAdminOrgPricingProposalsFailure(error))
            });
    };
}

export function deleteAdminOrgPricingProposal(id) {
    return dispatch => {
        return adminApi.deleteAdminOrgPricingProposal(id)
            .then(json => {
                dispatch(deleteAdminOrgPricingProposalSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteAdminOrgPricingProposalFailure(error))
            });
    }
}


export function fetchAdminOrgPricingProposalById(id) {
    return dispatch => {
        dispatch(fetchAdminOrgPricingProposalsBegin());//calling this action to show loading
        return adminApi.getAdminOrgPricingProposalDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchAdminOrgPricingProposalByIdSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchAdminOrgPricingProposalByIdFailure(error))
            });
    }
}

export function updateAdminOrgPricingProposal(data, id) {
    return dispatch => {
        dispatch(updateAdminOrgPricingProposalBegin());
        let res = adminApi.updateAdminOrgPricingProposal(data, id)
            .then(json => {
                dispatch(updateAdminOrgPricingProposalsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateAdminOrgPricingProposalsFailure(error));

            })
        return res;
    }
}

export const fetchAdminOrgPricingProposalsBegin = () => ({
    type: FETCH_ADMIN_ORG_PRICING_PROPOSALS_BEGIN
});

export const fetchAdminOrgPricingProposalsSuccess = json => ({
    type: FETCH_ADMIN_ORG_PRICING_PROPOSALS_SUCCESS,
    data: json.data
});

export const fetchAdminOrgPricingProposalsFailure = error => ({
    type: FETCH_ADMIN_ORG_PRICING_PROPOSALS_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export const deleteAdminOrgPricingProposalSuccess = (json) => ({
    type: DELETE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS,
    data: json.data.PRICING_PROPOSAL,
    msg: json.message
})

export const deleteAdminOrgPricingProposalFailure = (error) => ({
    type: DELETE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export const fetchAdminOrgPricingProposalByIdBegin = () => ({
    type: GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_BEGIN
});
export const fetchAdminOrgPricingProposalByIdSuccess = json => ({
    type: GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_SUCCESS,
    data: json.data.PRICING_PROPOSAL
});

export const fetchAdminOrgPricingProposalByIdFailure = error => ({
    type: GET_ADMIN_ORG_PRICING_PROPOSAL_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export function updateAdminOrgPricingProposalBegin() {
    return {
        type: UPDATE_ADMIN_ORG_PRICING_PROPOSAL_BEGIN
    }
}

export function updateAdminOrgPricingProposalsSuccess(json) {
    return {
        type: UPDATE_ADMIN_ORG_PRICING_PROPOSAL_SUCCESS,
        data: json.data.PRICING_PROPOSAL
    }
}

export function updateAdminOrgPricingProposalsFailure(error) {
    return {
        type: UPDATE_ADMIN_ORG_PRICING_PROPOSAL_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function clearAdminOrgPricingProposals() {
    return {
        type: CLEAR_ADMIN_ORG_PRICING_PROPOSALS
    }
}
export function setOrgPricingProposalsFilters(filters) {
    return {
        type: SET_ADMIN_ORG_PRICING_PROPOSALS_FILTER,
        payload: {filters}
    }
}
export function clearOrgPricingProposalsFilters() {
    return {
        type: CLEAR_ADMIN_ORG_PRICING_PROPOSALS_FILTER
    }
}
export function clearOrgPricingProposalsNotification() {
    return {
        type: CLEAR_PRICING_PROPOSAL_NOTIFICATION
    }
}



// Private methods

function handleErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}
