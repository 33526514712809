import React, {Component} from "react";
import {withStyles, Paper, Typography} from "@material-ui/core";

class AddNew extends Component {
  render() {
    const {classes, title} = this.props;
    const pluralize = (word) => {
      if(word[word.length - 1] === "y") {
        return word.slice(0, word.length - 1) + `ies`;
      } else {
        return word + `s`;
      }
    };

    return (
      <Paper className={classes.empty}>
        <Typography variant="h3">No {pluralize(title)} yet</Typography>
        <Typography variant="subtitle1">
          To create a new {title} click the blue button above
        </Typography>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  empty: {
    justifyContent: "space-around",
    display: "flex",
    flexDirection: "column",
    minHeight: "200px",
    padding: theme.spacing(3),
    textAlign: "center",
    opacity: "0.7",
    marginTop: theme.spacing(3),
  },
});

export default withStyles(styles)(AddNew);
