import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';
import { updateLowStockFilter } from 'actions/spaceActions'
import { useDispatch } from 'react-redux';
import keepspaceApi from 'services/keepspaceApi';

import { UID } from 'AppConstants';
const useStyles = makeStyles(theme => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium
  },
  type: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const Notifications = props => {
  const { className, ...rest } = props;
  const dispatch = useDispatch()
  const [notifications, setNotifications] = useState({
    needAttention: null,
    unread: null,
    lowStock: null
  });
  const classes = useStyles();

  // let conversations = useSelector(state =>  state.publicCommentsReducer.conversations)                   

  useEffect(() => {
    let ignore = false;

    const fetchOrderNotifications = () => {
      keepspaceApi.getNotifications()
        .then(json => {
          if (!ignore) setNotifications(notifications => ({ unread: json.unread, needAttention: json.needAttention, lowStock: json.lowStock }))
        })
        .catch(error => console.log(error))
    }

    if (!ignore) {
      fetchOrderNotifications();
    }

    return () => {
      ignore = true; // Avoid memory leaks
    }

  }, []);

  const icons = {
    invite: <SendIcon />,
    message: <MailIcon />,
    payout: <PaymentIcon />
  };

  const lowStockClick = () => {
    dispatch(updateLowStockFilter(true));
  }

  const notificationRows = [

    {
      id: uuid(),
      value: notifications.lowStock || 0,
      message: 'SKUs have fallen below your recommended low stock levels',
      link: `/user/${UID}/space/products/no_stock`,
      type: 'payout'
    },
    {
      id: uuid(),
      value: notifications.unread || 0,
      message: 'Unread chat messages awaiting you',
      link: `/user/${UID}/chat`,
      type: 'message'
    }
  ];

  const notificationCount = notificationRows.some(notifications => notifications.value > 0);



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {notificationCount &&
        <List>
          {notificationRows.map((notification, i) => notification.value > 0 ? (
            <ListItem
              divider={i < notificationRows.length - 1}
              key={notification.id}
            >
              <ListItemIcon>{icons[notification.type]}</ListItemIcon>
              <ListItemText>
                <Typography variant="body2">
                  <span className={classes.value}>{notification.value}</span>{' '}
                  {notification.message}
                </Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                <Tooltip title="View">
                  <IconButton
                    edge="end"
                    size="small"
                    component={RouterLink}
                    to={notification.link}
                    onClick={lowStockClick}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ) : (null))}
        </List>}

    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
