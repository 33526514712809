import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    layoutDesign: {
      marginTop: '20px',
      fontFamily: "lato",
      color: "#595959",
    },
}));


const CustomerEmails = props => {
  const { values } = props;
  const classes = useStyles();

  return (
      <>
          <Typography>KeepSpace will send a dispatch email to your customers notifying them that the order has left the warehouse and in on route to them via the carrier
          [This email will have a tracking link for your customer to follow along with the carrier. This reduces the customer support request on tracking update
          request to your company.</Typography>

          <Grid container spacing={8}>
            <Grid item xs={false} sm={false} md={4}>
            </Grid>

            <Grid item xs={12} sm={12} md={8}>

                  <div className={classes.layoutDesign}>
                        <Typography variant="h6" >Layout Design</Typography>

                        <div className={classes.emailContent} style={{border: '1px black solid', padding: '30px', marginTop: "20px"}}>
                            <div style= {{marginTop: '20px'}}>
                              <Typography style={{color: "#62a1d1"}} paragraph> Hi (Buyers First name) </Typography>
                              <Typography style={{fontWeight: "bold"}}>Your shipment from {values.correspondence_company_name} is on its way!</Typography>
                              <br/>
                              <Typography>Shipped item: (Product Title) (Product description) </Typography>
                            </div>

                            <div className={classes.customerDetails}  style={{"margin":"50px 0","borderTop":"1px solid","padding":"30px 0","borderBottom":"1px solid","borderImage":"linear-gradient(to right, #838383, #0000 70%) 1"}}>
                                <Typography>Your order was sent to:</Typography>
                                <br/>
                                <div style={{fontSize: "14px", marginLeft: "14px"}}>
                                  <Typography>(Customers First Name) (Last Name)</Typography>
                                  <Typography>(Customers Address line 1)</Typography>
                                  <Typography>(Customers Address line 2)</Typography>
                                  <Typography>(Customers Suburb)</Typography>
                                  <Typography>(Customers State) (Customers Postcode)</Typography>
                                  <Typography>(Customers Country)</Typography>
                                </div>
                            </div>

                            <div>
                              <Typography> The shipment was sent with (Carrier) on (processed time and date)</Typography>
                              <br/>
                              <Typography style={{fontWeight: "bold"}}>You can track your products movements by clicking on the link below</Typography>
                              <Typography paragraph>(Tracking Link)</Typography>
                              <br/>
                              <Typography variant="subtitle2" paragraph>
                                Please be aware this is an automated email and some dispatches such as letters will not come with a tracking link.
                                Please understand that some carriers systems may take up to 12 hours before their tracking information becomes avaliable.
                                Should there be any issue in sourcing your tracking information from the carrier please contact the carrier provider first with your
                                tracking details here for the quickest updates. If issues persist then please contact {values.correspondence_company_name} for further information.
                              </Typography>

                              <Typography>Picked, Packed and Disptached with Love and Care,</Typography>

                              <div style={{ display: "flex", flexDirection: "row", paddingTop: '25px' }}>
                                <img
                                  src="http://www.keepspace.com.au/wp-content/uploads/2016/07/Keepspace-Logo.png"
                                  alt="KeepSpace"
                                  style={{ border: '0px', height: '45px', marginRight: '10px' }}
                                />
                                <div>
                                  <Typography variant="h6">KeepSpace</Typography>
                                  <Typography variant="subtitle2">Your eCommerce Order Fulfilment Service.</Typography>
                                </div>
                              </div>
                            </div>
                        </div>

                  </div>
            </Grid>
        </Grid>
      </>
  );
};


export default CustomerEmails;
