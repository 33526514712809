import React, {useState, useEffect} from 'react';
import {withStyles, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import General from './Components/General/General';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import {useDispatch} from 'react-redux';
import {createOrganisation} from 'actions/organisationActions';
import {UID} from 'AppConstants';

function Organisation(props) {
  const {classes, error, history} = props;
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setValues({
      name: '',
      abn: '',
      web_url: '',
      address_line_1: '',
      address_line_2: '',
      email: '',
      phone: '',
      logo: '',
      preview: '',
    });
  }, []);

  useEffect(() => {
    if(error && error.statusText) setOpenSnackbar(true)
  }, [error])

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const onImageUpload = files => {
    let reader = new FileReader();
    let file = files[0]
    reader.onloadend = () => (
      setValues({
        ...values,
        logo: files,
        preview: reader.result
      })
    );
    if(file) {
      reader.readAsDataURL(file);
    }
  }

  const handleChange = event => {
    event.persist();

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const handleSubmit = event => {

    event.preventDefault();
    var formData = new FormData();

    Object.keys(values).forEach(e => {
      if(e === 'logo' || e === 'preview') return;
      formData.append(e, values[e]);
    });

    if(values.logo[0]) {
      formData.append('logo', values.logo[0], values.logo[0].name)
    }

    dispatch(createOrganisation(formData))
      .then((result) => {
        if(result) {
          // result is null when in error
          setOpenSnackbar(true);
          history.push(`/user/${UID}/organisations`)
        }
      });
  };

  return (
    <div className={classes.root}>
      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
        error={(error && error.statusText) ? error.statusText : null}
        messageText="Successfully saved"
      />

      <Typography variant="h3" style={{fontSize: '11px', marginBottom: '8px'}}>SETTINGS</Typography>
      <Typography variant="h1" style={{fontSize: '24px', marginBottom: 10}}>Create New Organisation</Typography>

      <General
        title="Create new organisation"
        error={error}
        values={values}
        handleSubmit={handleSubmit}
        onImageUpload={onImageUpload}
        handleChange={handleChange}
        action="Create"
      />
    </div>
  )
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  }
})

const mapPropsToState = (state, ownProps) => ({
  ...ownProps,
  currentOrganisation: state.organisationsReducer.currentOrganisation,
  error: state.organisationsReducer.error,
})

export default connect(mapPropsToState)(withStyles(styles)(Organisation));


