import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Dropzone from 'components/Upload/Dropzone';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

const useStyles = makeStyles(theme => ({
  layoutDesign: {
    marginTop: '20px',
    fontFamily: "lato",
    color: "#595959",
  },
  img: {
    height: 250,
    width: 250,
    // zIndex: 1041,
    marginTop: '20px',
    objectFit: 'contain',
    border: '1px solid black',
    padding: '5px',
  },
  progress: {
    borderRadius: '50%',
    height: 250,
    width: 250,
    zIndex: 1041
  },
  textInput: {
    marginTop: '20px',
    width: '100%'
  },
  customizedContent: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
}));


const BrandingEmails = props => {
  const { values, handleChange, setValues } = props;

  const classes = useStyles();

  const onImageUpload = files => {
    let reader = new FileReader();
    let file = files[0]
    reader.onloadend = () => (
      setValues({
        ...values,
        logo: files,
        preview: reader.result
      })
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }
  const [errImgName, setErrImgName] = useState(null);
  const handleSnackbarClose = () => {
    setErrImgName(null);
  };

  return (
    <>
      <Typography>keepSpace will ghost write a dispatch email to your customers on your behalf informing them that the order has left the warehouse and in on route to them
        via the carrier. This email will have a tracking link for your customer to follow along with the carrier. This reduces the customer support request on tracking
        update request to your company while up keeping your brand integrity</Typography>


      <Grid container spacing={8}>
        <Grid item xs={12} sm={12} md={4}>
          <div className={classes.customizedContent} >
            <div>
              <Typography align='left' variant="h6" >Company Logo</Typography>
              <div className={classes.imageUploadZone}>
                <div className={classes.imageFileList}>
                  <Dropzone
                    disabled={false}
                    onFilesAdded={onImageUpload}
                    accept="image/png,image/jpg,image/jpeg"
                    preview={values.preview}
                    errImgName={setErrImgName}
                  />
                </div>
              </div>
            </div>
            <div className={classes.textInput}>

              <TextField
                fullWidth
                label={'Final Sentance'}
                name='final_sentance'
                onChange={handleChange}
                value={values.final_sentance}
                variant="outlined"
                SelectProps={{ native: true }}
              />
            </div>
            <div className={classes.textInput}>

              <TextField
                fullWidth
                label={'Your company slogan'}
                name='slogan'
                onChange={handleChange}
                value={values.slogan}
                variant="outlined"
              // SelectProps={{ native: true }}
              />
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>

          <div className={classes.layoutDesign}>
            <Typography variant="h6" >Layout Design</Typography>

            <div className={classes.emailContent} style={{ border: '1px black solid', padding: '30px', marginTop: "20px" }}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img
                  src={values.preview ? values.preview : 'https://www.getlivewire.com/wp-content/uploads/2018/10/Your-Logo-here.png'}
                  alt={values.company}
                  style={{ border: '0px', verticalAlign: 'middle', maxHeight: '45px' }}
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                <Typography style={{ color: "#62a1d1" }} paragraph> Hi (Buyers First name) </Typography>
                <Typography style={{ fontWeight: "bold" }}>Your shipment from {values.correspondence_company_name} is on its way!</Typography>
                <br />
                <Typography>Shipped item/s: (Product Title)</Typography>
              </div>

              <div className={classes.customerDetails} style={{ "margin": "50px 0", "borderTop": "1px solid", "padding": "30px 0", "borderBottom": "1px solid", "borderImage": "linear-gradient(to right, #838383, #0000 70%) 1" }}>
                <Typography>Your order was sent to:</Typography>
                <br />
                <div style={{ fontSize: "14px", marginLeft: "14px" }}>
                  <Typography>(Customers First Name) (Last Name)</Typography>
                  <Typography>(Customers Address line 1)</Typography>
                  <Typography>(Customers Address line 2)</Typography>
                  <Typography>(Customers Suburb)</Typography>
                  <Typography>(Customers State) (Customers Postcode)</Typography>
                  <Typography>(Customers Country)</Typography>
                </div>
              </div>

              <div>
                <Typography> The shipment was sent with (Carrier) on (processed time and date)</Typography>
                <br />
                <Typography style={{ fontWeight: "bold" }}>You can track your products movements by clicking on the link below</Typography>
                <Typography paragraph>(Tracking Link)</Typography>
                <br />
                <Typography variant="subtitle2" paragraph>
                  Please be aware this is an automated email and some dispatches such as letters will not come with a tracking link.
                  Please understand that some carriers systems may take up to 12 hours before their tracking information becomes avaliable.
                  Should there be any issue in sourcing your tracking information from the carrier please contact the carrier provider first with your
                  tracking details here for the quickest updates. If issues persist then please contact {values.correspondence_company_name} for further information.
                </Typography>

                <Typography>{values.final_sentance || "(Final Sentance)"}</Typography>

                <div style={{ display: "flex", flexDirection: "row", paddingTop: '25px' }}>
                  <img
                    src={values.preview ? values.preview : 'https://www.getlivewire.com/wp-content/uploads/2018/10/Your-Logo-here.png'}
                    alt={values.company}
                    style={{ border: '0px', height: '45px', marginRight: '10px' }}
                  />
                  <div>
                    <Typography variant="h6">{values.correspondence_company_name || "(Your Company name)"}</Typography>
                    <Typography variant="subtitle2">{values.slogan || "(Your company slogan)"}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Grid>

      </Grid>

      <SuccessSnackbar
        open={Boolean(errImgName)}
        error={`Select only PNG, JPG or JPEG file`}
        onClose={handleSnackbarClose}
      />
    </>
  );
};


export default BrandingEmails;
